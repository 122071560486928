import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { gql, useMutation } from "@apollo/client";
import { jwtDecode } from "jwt-decode";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Navigate } from "react-router-dom";
import { client } from "../../apollo";

const LOGIN_WITH_PASSWORD = gql`
  mutation LoginWithPassword($email: EmailAddress!, $password: String!) {
    loginWithPassword(email: $email, password: $password) {
      accessToken
    }
  }
`;

const USER_ROLE_MAPPING = gql`
  query PaginateUserRoleMappings($filter: UserRoleMappingFilter) {
    paginateUserRoleMappings(filter: $filter, limit: 1) {
      docs {
        id
        roles
      }
    }
  }
`;

export function SignIn() {
  const [formValue, setFormValue] = useState({
    email: "",
    password: "",
  });
  const [mutateFunction, { data, loading, error, reset }] =
    useMutation(LOGIN_WITH_PASSWORD);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const result = await mutateFunction({
        variables: { email: formValue.email, password: formValue.password },
      });
      localStorage.setItem(
        "accessToken",
        result.data.loginWithPassword.accessToken
      );
      const userRoleMappings = await client.query({
        query: USER_ROLE_MAPPING,
        variables: {
          filter: {
            userId: {
              eq: (jwtDecode(result.data.loginWithPassword.accessToken) as any)
                .id,
            },
          },
        },
      });

      console.log(userRoleMappings);
      if (userRoleMappings.data.paginateUserRoleMappings.docs.length >= 0) {
        localStorage.setItem(
          "role",
          userRoleMappings.data.paginateUserRoleMappings.docs[0].roles[0]
        );

        localStorage.setItem(
          "roleMapping",
          userRoleMappings.data.paginateUserRoleMappings.docs[0].id
        );
      }
    } catch (error) {
      // Do nothing
    }
  };

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value,
    } as any);
  };

  if (data) {
    return <Navigate to="/shops" replace={true} />;
  }

  return (
    <Box
      sx={{
        width: 500,
        maxWidth: "100%",
        padding: "2rem",
        marginTop: "15vh",
        margin: "15vh auto",
      }}
    >
      <h1>Đăng nhập Quet.vn</h1>
      <form onSubmit={onSubmit}>
        <TextField
          required
          id="email"
          type="email"
          name="email"
          label="email"
          variant="outlined"
          fullWidth
          sx={{ marginBottom: "2rem" }}
          value={formValue.email}
          onChange={handleInput}
        />
        <TextField
          required
          id="password"
          type="password"
          name="password"
          label="password"
          variant="outlined"
          fullWidth
          sx={{ marginBottom: "2rem" }}
          value={formValue.password}
          onChange={handleInput}
        />
        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          fullWidth
          loading={loading}
        >
          Đăng nhập
        </LoadingButton>
        <Dialog
          open={!!error}
          onClose={reset}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Có lỗi khi đăng nhập"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {(error?.graphQLErrors[0]?.extensions?.message as any) ||
                "Có lỗi xảy ra"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={reset} autoFocus>
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </Box>
  );
}
