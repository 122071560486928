import "./order.css";
import Chip from "@mui/material/Chip";

import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

export const PaymentStatus = (props: { status: string }) => {
  const configs = {
    PENDING: <Chip icon={<HourglassBottomIcon />} label="Chưa thu" color="info" />,
    PAID: <Chip icon={<CheckCircleIcon />} label="Đã thu" color="success" />,
    REFUNDED: <Chip icon={<CancelIcon />} label="Hoàn tiền" color="warning" />,
    PARTIALLY_REFUNDED: <Chip icon={<CancelIcon />} label="Hoàn một phần" color="info" />,
  };
  return configs[props.status as keyof typeof configs];
};
