import "./order.css";
import Chip from "@mui/material/Chip";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import LocalDiningIcon from '@mui/icons-material/LocalDining';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';

export const DeliveryType = (props: { deliveryType: string }) => {
  const configs = {
    DELIVERY: (
      <Chip icon={<DeliveryDiningIcon />} label="Giao" color="info" />
    ),
    EAT_IN: (
      <Chip icon={<LocalDiningIcon />} label="Tại quán" color="info" />
    ),
    TAKE_AWAY: (
      <Chip icon={<ShoppingBagIcon />} label="Mang về" color="info" />
    ),
  };
  return configs[props.deliveryType as keyof typeof configs];
};
