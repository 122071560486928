import { Navigate, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { gql, useQuery } from "@apollo/client";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import LinearProgress from "@mui/material/LinearProgress";
import ListItemButton from "@mui/material/ListItemButton";

export function Shops() {
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(gql`
    query {
      paginateShops {
        docs {
          id
          name
          code
          logo
        }
      }
    }
  `, {  });

  if (error) return <Navigate to="/sign-in" replace />;

  if (loading) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }

  const hasOnlyOneShop = data.paginateShops.docs.length === 1;
  if (hasOnlyOneShop) {
    return <Navigate to={`/${data.paginateShops.docs[0].code}`} />;
  }

  return (
    <Box sx={{ width: "100%", padding: "1rem", bgcolor: "background.paper" }}>
      <h1>Cửa hàng của tôi</h1>
      <List sx={{ width: "100%" }}>
        {data.paginateShops.docs.map((shop: any) => (
          <ListItemButton
            key={shop.id}
            sx={{ "&:hover": { fontWeight: 900 } }}
            onClick={() => navigate(`/${shop.code}`)}
          >
            <ListItemAvatar>
              <Avatar
                alt={shop.name}
                src={`https://img.quet.vn${shop.logo}?size=300x300`}
              />
            </ListItemAvatar>
            <ListItemText primary={shop.name} secondary={`/${shop.code}`} />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );
}
