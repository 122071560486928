import { gql } from "@apollo/client";

export const PAGINATE_PRODUCTS = gql`
  query PaginateProducts(
    $sort: ProductSort
    $filter: ProductFilter
    $page: Int!
    $limit: Int!
  ) {
    paginateProducts(sort: $sort, filter: $filter, page: $page, limit: $limit) {
      docs {
        id
        seqId
        name
        categories {
          name
          position
        }
        price {
          amount
          currency
        }
        image
        shop {
          code
        }
        availableHours {
          day
          start
          end
        }
        active
        outOfStockUntil
      }
    }
  }
`;
