import * as React from "react";
import { gql, useMutation } from "@apollo/client";

import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { blue } from "@mui/material/colors";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InventoryIcon from '@mui/icons-material/Inventory';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      id
    }
  }
`;

export const Controls = (props: { product: any; refetch: any }) => {
  const [open, setOpen] = React.useState(false);
  const [openToast, setOpenToast] = React.useState(false);
  const [mutateFunction, { reset }] = useMutation(UPDATE_PRODUCT);

  const updateStatus = (updateObject: any) => {
    mutateFunction({
      variables: {
        input: {
          id: props.product.id,
          ...updateObject,
        },
      },
    }).then(() => {
      setOpen(false);
      setOpenToast(true);
      reset();
      props.refetch();
    });
  };

  const actionConfigs = [
    {
      label: "Đã có hàng",
      icon: <InventoryIcon />,
      disable: props.product.outOfStockUntil === null,
      payload: () => ({ outOfStockUntil: null }),
    },
    {
      label: "Ẩn món",
      icon: <VisibilityOffIcon />,
      disable: !props.product.active,
      payload: () => ({ active: false }),
    },
    {
      label: "Hiện món",
      icon: <VisibilityIcon />,
      disable: props.product.active,
      payload: () => ({ active: true }),
    },
    {
      label: "Hết hàng hôm nay",
      icon: <CloseIcon />,
      disable: false,
      payload: () => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 1);
        currentDate.setHours(0);
        currentDate.setMinutes(1);
        currentDate.setSeconds(0);
        currentDate.setMilliseconds(0);
        return { outOfStockUntil: currentDate };
      },
    },
    {
      label: "Hết hàng trong 1h",
      icon: <CloseIcon />,
      disable: false,
      payload: () => {
        const currentDate = new Date();
        currentDate.setHours(currentDate.getHours() + 1);
        return { outOfStockUntil: currentDate };
      },
    },
    {
      label: "Hết hàng trong 2h",
      icon: <CloseIcon />,
      disable: false,
      payload: () => {
        const currentDate = new Date();
        currentDate.setHours(currentDate.getHours() + 2);
        return { outOfStockUntil: currentDate };
      },
    },
    {
      label: "Hết hàng tới 2 ngày",
      icon: <CloseIcon />,
      disable: false,
      payload: () => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 2);
        currentDate.setHours(0);
        currentDate.setMinutes(1);
        currentDate.setSeconds(0);
        currentDate.setMilliseconds(0);
        return { outOfStockUntil: currentDate };
      },
    },
    {
      label: "Hết hàng tới 3 ngày",
      icon: <CloseIcon />,
      disable: false,
      payload: () => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 3);
        currentDate.setHours(0);
        currentDate.setMinutes(1);
        currentDate.setSeconds(0);
        currentDate.setMilliseconds(0);
        return { outOfStockUntil: currentDate };
      },
    },
    {
      label: "Hết hàng tới 7 ngày",
      icon: <CloseIcon />,
      disable: false,
      payload: () => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 7);
        currentDate.setHours(0);
        currentDate.setMinutes(1);
        currentDate.setSeconds(0);
        currentDate.setMilliseconds(0);
        return { outOfStockUntil: currentDate };
      },
    },
    {
      label: "Hết hàng 1 năm",
      icon: <CloseIcon />,
      disable: false,
      payload: () => {
        const currentDate = new Date();
        currentDate.setFullYear(currentDate.getFullYear() + 1);
        currentDate.setHours(0);
        currentDate.setMinutes(1);
        currentDate.setSeconds(0);
        currentDate.setMilliseconds(0);
        return { outOfStockUntil: currentDate };
      },
    },
  ];

  return (
    <>
      <Button
        variant="contained"
        endIcon={<EditIcon />}
        onClick={() => setOpen(true)}
      >
        Sửa
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Cập nhật sản phẩm"}</DialogTitle>
        <DialogActions>
          <List sx={{ pt: 0 }}>
            {actionConfigs
              .filter((config) => !config.disable)
              .map((config, index) => (
                <ListItem key={index}>
                  <ListItemButton onClick={() => {
                    updateStatus(config.payload());
                  }}>
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: blue[100] }}>{config.icon}</Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={config.label} />
                  </ListItemButton>
                </ListItem>
              ))}
          </List>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openToast}
        onClose={() => setOpenToast(false)}
        color="success"
        autoHideDuration={1000}
      >
        <Alert
          onClose={() => setOpenToast(false)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Thành công
        </Alert>
      </Snackbar>
    </>
  );
};
