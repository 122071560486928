import "./order.css";
import Chip from "@mui/material/Chip";

import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

export const DeliveryStatus = (props: { deliveryStatus: string }) => {
  const configs = {
    PENDING: (
      <Chip icon={<HourglassBottomIcon />} label="Chờ" color="warning" />
    ),
    ACCEPTED: (
      <Chip icon={<ThumbUpAltIcon />} label="Đã xác nhận" color="info" />
    ),
    PREPARING: (
      <Chip icon={<WhatshotIcon />} label="Đang chế biến" color="info" />
    ),
    DELIVERING: (
      <Chip icon={<DeliveryDiningIcon />} label="Đang giao" color="info" />
    ),
    COMPLETED: (
      <Chip icon={<CheckCircleIcon />} label="Hoàn thành" color="success" />
    ),
    CANCELLED: (
      <Chip icon={<CancelIcon />} label="Đang chế biến" color="error" />
    ),
  };
  return configs[props.deliveryStatus as keyof typeof configs];
};
