import "./order.css";
import * as React from "react";
import Chip from "@mui/material/Chip";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import PercentIcon from "@mui/icons-material/Percent";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import { DeliveryType } from "./delivery-type";
import { DeliveryStatus } from "./delivery-status";
import { PaymentProvider } from "./payment-provider";
import { PaymentStatus } from "./payment-status";
import { Controls } from "./controls";
import Button from "@mui/material/Button";
import PhoneIcon from "@mui/icons-material/Phone";

export function Order(props: {
  order: any;
  refetch: any;
  children?: React.ReactNode;
}) {
  const { order } = props;
  return (
    <CardContent sx={{ border: "1px dotted grey", margin: "1rem auto" }}>
      <Box
        display="flex"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Button
          variant="contained"
          href={`/${order.shop.code}/${order.seqId}`}
          color="info"
        >
          #{order.seqId}
        </Button>
        <Link
          display="block"
          href={`mailto:${order.userEmail}`}
          sx={{ textDecoration: "none" }}
        >
          {order.userEmail}
        </Link>
      </Box>
      <Divider sx={{ margin: "0.5rem 0" }} />
      {order.lineItems.map((item: any) => {
        const optionValueNameById: any = {};
        item.options.forEach((option: any) => {
          option.values.forEach((value: any) => {
            optionValueNameById[value.id as string] = value.name;
          });
        });
        return (
          <React.Fragment key={item.id}>
            <p>
              <strong>{item.name}</strong> x <strong>{item.quantity}</strong> ={" "}
              {(item.price.amount * item.quantity).toLocaleString("vi-VN", {
                currency: "VND",
              })}
              {item.choseOptionValueIds?.map((optionValueId: string) => {
                return (
                  <span key={optionValueId}>
                    <br />
                    <i>{optionValueNameById[optionValueId as string]}</i>
                  </span>
                );
              })}
              {item.note && (
                <span>
                  <br />
                  <i>* {item.note}</i>
                </span>
              )}
            </p>
          </React.Fragment>
        );
      })}
      <Divider sx={{ margin: "0.5rem 0" }} />
      {order.discounted.amount > 0 && (
        <div style={{ margin: "0.7rem 0" }}>
          Giảm giá:{" "}
          {order.discounted.amount.toLocaleString("vi-VN", { currency: "VND" })}{" "}
          <Chip
            icon={<PercentIcon />}
            label={order.appliedPromotions[0].code}
            color="info"
          />
        </div>
      )}
      <div>
        Tổng:{" "}
        {order.total.amount.toLocaleString("vi-VN", {
          currency: "VND",
        })}{" "}
        <PaymentProvider provider={order.payments[0].provider} />{" "}
        <PaymentStatus status={order.paymentStatus} />
      </div>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <Box>
        <DeliveryType deliveryType={order.deliveryType} />{" "}
        <Chip
          icon={<WatchLaterIcon />}
          label={new Date(order.scheduledAt).toLocaleTimeString(["en-GB"], {
            hour: "2-digit",
            minute: "2-digit",
          })}
          color="info"
        />{" "}
        <DeliveryStatus deliveryStatus={order.deliveryStatus} />
      </Box>
      {order.deliveryType === "DELIVERY" && (
        <address style={{ margin: "1rem 0 0 0" }}>
          Địa chỉ: {" "}
          {order.shippingAddress}
        </address>
      )}
      {order.note !== "" && (
        <p style={{ margin: "0.3rem auto" }}>
          Ghi chú: <i>{order.note}</i>
        </p>
      )}
      <Divider sx={{ margin: "0.5rem 0" }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "right",
          button: { marginLeft: "0.5rem" },
        }}
      >
        <Button
          variant="outlined"
          href={`tel:${order.userPhone}`}
          startIcon={<PhoneIcon />}
        >
          {order.userPhone.replace("+84", "0")}
        </Button>
        <span>{"  "}</span>
        <Controls order={order} refetch={props.refetch} />
      </Box>
    </CardContent>
  );
}
