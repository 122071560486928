import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { PAGINATE_PRODUCTS } from "./apis";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";

export const Product = ({ product }: any) => {
  return (
    <CardContent sx={{ border: "1px dotted grey", margin: "1rem auto" }}>
      <Box
        display="flex"
        sx={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Button
          variant="contained"
          href={`/${product.shop.code}/products/${product.seqId}`}
          color="info"
        >
          #{product.seqId}
        </Button>
        <Link display="block" href={`mailto:a`} sx={{ textDecoration: "none" }}>
          vanpho01@gmail.com
        </Link>
      </Box>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <h1 style={{ fontSize: "1.5rem" }}>{product.name}</h1>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <Avatar
        variant="square"
        alt={product.name}
        sx={{ width: 100, height: 100 }}
        src={`https://img.quet.vn${product.image}?size=300x300&_p=${product.id}`}
      />
    </CardContent>
  );
};

export const ProductDetails = () => {
  const { productSeqId, shopCode } = useParams();
  const { data, refetch, loading } = useQuery(PAGINATE_PRODUCTS, {
    variables: {
      page: 1,
      limit: 1,
      filter: {
        seqId: { eq: productSeqId },
      },
    },
  });
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ padding: "0 1rem" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "1rem 0",
        }}
      >
        {data.paginateProducts.docs.length > 0 && (
          <h2 style={{ margin: 0 }}>
            Sản phẩm #{data.paginateProducts.docs[0].seqId}
          </h2>
        )}
        <Button
          startIcon={<ArrowBackIcon />}
          variant="contained"
          href={`/${shopCode!}/menu`}
        >
          Menu
        </Button>
      </Box>
      {data.paginateProducts.docs.length === 0 && (
        <Box>Không tìm thấy sản phẩm!</Box>
      )}
      {data.paginateProducts.docs.length > 0 && (
        <Product product={data.paginateProducts.docs[0]} refetch={refetch} />
      )}
    </Box>
  );
};
