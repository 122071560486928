import type * as React from "react";
import { useState, useContext } from "react";

import { useQuery } from "@apollo/client";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";

import { PAGINATE_PRODUCTS } from "./apis";
import { Controls } from "./controls";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { ShopContext } from "../shop-context";

export function ProductList(props: { children?: React.ReactNode }) {
  const { shop } = useContext(ShopContext)
  const [statusFilter, setStatusFilter] = useState("ALL");
  const [dayFilter, setDayFilter] = useState("ALL");
  const { loading, data, refetch } = useQuery(PAGINATE_PRODUCTS, {
    variables: {
      page: 1,
      limit: 100,
      sort: { createdAt: "DESC" },
      filter: { shopId: { eq: shop.id } }
    },
  });
  if (loading) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }
  if (data.paginateProducts.docs.length === 0) {
    return (
      <Box sx={{ textAlign: "center", margin: "10vh" }}>
        <p>Không có sản phẩm nào</p>
      </Box>
    );
  }

  const docs = [...data.paginateProducts.docs];

  return (
    <>
      <Box>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 60 }}>
          <InputLabel id="demo-simple-select-standard-label">
            Trạng thái
          </InputLabel>
          <Select
            value={statusFilter}
            onChange={(event) => setStatusFilter(event.target.value)}
            label="Ẩn"
          >
            <MenuItem value="ALL">Tất cả</MenuItem>
            <MenuItem value="SELLING">Đang bán</MenuItem>
            <MenuItem value="OUT_OF_STOCK">Đang hết hàng</MenuItem>
            <MenuItem value="HIDDEN">Đang ẩn</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-standard-label">
            Ngày trong tuần
          </InputLabel>
          <Select
            value={dayFilter}
            onChange={(event) => setDayFilter(event.target.value)}
            label="Ngày trong tuần"
          >
            <MenuItem value="ALL">Tất cả</MenuItem>
            <MenuItem value="TODAY">Hôm nay</MenuItem>
            <MenuItem value="TOMORROW">Ngày mai</MenuItem>
            <MenuItem value="ALL_WEEK">Suốt tuần</MenuItem>
            <MenuItem value="MONDAY">Thứ 2</MenuItem>
            <MenuItem value="TUESDAY">Thứ 3</MenuItem>
            <MenuItem value="WEDNESDAY">Thứ 4</MenuItem>
            <MenuItem value="THURSDAY">Thứ 5</MenuItem>
            <MenuItem value="FRIDAY">Thứ 6</MenuItem>
            <MenuItem value="SATURDAY">Thứ 7</MenuItem>
            <MenuItem value="SUNDAY">CN</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <List sx={{ width: "100%", maxWidth: 480, bgcolor: "background.paper" }}>
        {docs
          .filter((product: any) => {
            if (statusFilter === "ALL") return true;
            if (statusFilter === "SELLING")
              return product.active && !product.outOfStockUntil;
            if (statusFilter === "OUT_OF_STOCK") return product.outOfStockUntil && product.active;
            if (statusFilter === "HIDDEN") return !product.active;
            return true;
          })
          .filter((product: any) => {
            if (dayFilter === "ALL") return true;
            if (dayFilter === "ALL_WEEK") {
              const length = (product.availableHours || []).length;
              if (length === 0) return true;
              if (length < 7) return false;
              const DAYS_OF_WEEK = [
                "MONDAY",
                "TUESDAY",
                "WEDNESDAY",
                "THURSDAY",
                "FRIDAY",
                "SATURDAY",
                "SUNDAY",
              ];
              return DAYS_OF_WEEK.every((day) =>
                product.availableHours.some(
                  (availableHour: any) => availableHour.day === day
                )
              );
            }
            if ((product.availableHours || []).length === 0) return true;
            const dayName = [
              "SUNDAY",
              "MONDAY",
              "TUESDAY",
              "WEDNESDAY",
              "THURSDAY",
              "FRIDAY",
              "SATURDAY",
            ][new Date().getDay()];
            if (dayFilter === "TODAY")
              return product.availableHours.some(
                (availableHour: any) => availableHour.day === dayName
              );

            if (dayFilter === "TOMORROW")
              return product.availableHours.some(
                (availableHour: any) =>
                  availableHour.day ===
                  [
                    "SUNDAY",
                    "MONDAY",
                    "TUESDAY",
                    "WEDNESDAY",
                    "THURSDAY",
                    "FRIDAY",
                    "SATURDAY",
                  ][(new Date().getDay() + 1) % 7]
              );

            return product.availableHours.some(
              (availableHour: any) => availableHour.day === dayFilter
            );
          })
          .sort((product1: any, product2: any) => {
            return (
              product1.categories[0].position - product2.categories[0].position
            );
          })
          .map((product) => {
            return (
              <Box
                key={product.id}
                sx={{
                  border: "1px dotted whitesmoke",
                  borderRadius: "0.5rem",
                  margin: "0.5rem 0",
                  padding: "0.5rem",
                  "&:hover": { opacity: 0.6 },
                }}
              >
                <Box
                  sx={{
                    margin: "auto 0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h3>{`#${product.seqId}`}</h3>
                  <Controls product={product} refetch={refetch} />
                </Box>
                <Divider sx={{ margin: "0.5rem 0" }} />
                <Box
                  sx={{
                    display: "flex",
                    flex: "1 5",
                    width: "100%",
                  }}
                >
                  <img
                    alt={product.name}
                    src={`https://img.quet.vn${product.image}?fit=crop&w=200&h=200&_p=${product.id}`}
                    sizes="70x70"
                    width={70}
                    height={70}
                  />
                  <Box sx={{ marginLeft: "1rem" }}>
                    <p>
                      <strong>{`${product.name}`}</strong>
                    </p>
                    <p>
                      {product.price.amount.toLocaleString("vi-VN", {
                        currency: "VND",
                      })}
                    </p>
                  </Box>
                </Box>
                <Divider sx={{ margin: "0.5rem 0" }} />
                <Box>
                  {(product.availableHours || []).length === 0 ? (
                    <Chip label={"Cả tuần"} color="info" />
                  ) : (
                    <Chip label={"Tùy ngày"} color="info" />
                  )}{" "}
                  {product.active === false && (
                    <>
                      <Chip label={"Món ẩn"} color="warning" />{" "}
                    </>
                  )}
                  {product.outOfStockUntil &&
                    new Date(product.outOfStockUntil).getTime() >
                      Date.now() && (
                      <>
                        <Chip label={"Hết hàng"} color="warning" />{" "}
                      </>
                    )}
                </Box>
              </Box>
            );
          })}
      </List>
    </>
  );
}
