import "./order.css";
import Chip from "@mui/material/Chip";

import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import LoyaltyIcon from "@mui/icons-material/Loyalty";

export const PaymentProvider = (props: { provider: string }) => {
  const configs = {
    CASH: <Chip icon={<LocalAtmIcon />} label="Tiền mặt" color="info" />,
    PAY_OS: <Chip icon={<AccountBalanceIcon />} label="Bank" color="info" />,
    POINT: <Chip icon={<LoyaltyIcon />} label="Điểm" color="info" />,
  };
  return configs[props.provider as keyof typeof configs];
};
