import { useQuery } from "@apollo/client";
import { PAGINATE_ORDERS } from "./apis";
import { Order } from "./order";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const OrderDetail = () => {
  const { orderSeqId, shopCode } = useParams();

  const { data, refetch, error, loading } = useQuery(PAGINATE_ORDERS, {
    variables: {
      page: 1,
      limit: 1,
      filter: {
        seqId: { eq: orderSeqId },
      },
    },
  });
  if (error) {
    return <div>{JSON.stringify(error)}</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ padding: "0 1rem" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "1rem 0",
        }}
      >
        {data.paginateOrders.docs.length > 0 && (
          <h1 style={{ margin: 0 }}>
            Đơn #{data.paginateOrders.docs[0].seqId}
          </h1>
        )}
        <Button
          startIcon={<ArrowBackIcon />}
          variant="contained"
          href={`/${shopCode!}`}
        >
          Quản lý
        </Button>
      </Box>
      {data.paginateOrders.docs.length === 0 && (
        <Box>Không tìm thấy đơn hàng!</Box>
      )}
      {data.paginateOrders.docs.length > 0 && (
        <Order order={data.paginateOrders.docs[0]} refetch={refetch} />
      )}
    </Box>
  );
};
