import type * as React from "react";
import { useContext, useRef, useState } from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { ShopContext } from "./shop-context";

export default function FixedBottomNavigation() {
  const [value, setValue] = useState("orders");
  const ref = useRef<HTMLDivElement>(null);
  const { shop } = useContext(ShopContext);
  const navigate = useNavigate();

  return (
    <Box sx={{ pb: 7 }} ref={ref}>
      <CssBaseline />
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction
            label="Đơn hàng"
            icon={<RestoreIcon />}
            onClick={() => navigate(`/${shop.code}`)}
            value="orders"
          />
          <BottomNavigationAction
            label="Menu"
            icon={<FavoriteIcon />}
            onClick={() => navigate(`/${shop.code}/menu`)}
            value="menu"
          />
        </BottomNavigation>
      </Paper>
    </Box>
  );
}
