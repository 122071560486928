import * as React from "react";
import Button from "@mui/material/Button";
import NotificationsIcon from "@mui/icons-material/Notifications";
import EditIcon from "@mui/icons-material/Edit";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { blue } from "@mui/material/colors";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { gql, useMutation } from "@apollo/client";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";

const UPDATE_ORDER = gql`
  mutation UpdateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      id
      deliveryStatus
      paymentStatus
    }
  }
`;

export const Controls = (props: { order: any; refetch: any }) => {
  const [open, setOpen] = React.useState(false);
  const [openToast, setOpenToast] = React.useState(false);
  const [mutateFunction, { loading, reset }] = useMutation(UPDATE_ORDER);

  const updateStatus = (updateObject: any) => {
    mutateFunction({
      variables: {
        input: {
          id: props.order.id,
          ...updateObject,
        },
      },
    }).then(() => {
      setOpen(false);
      setOpenToast(true);
      reset();
      props.refetch();
    });
  };

  const deliveryStatusToPoint = {
    PENDING: 0,
    ACCEPTED: 1,
    PREPARING: 2,
    DELIVERING: 3,
    COMPLETED: 4,
  };

  const actionConfigs = [
    {
      label: "Đang chế biến",
      icon: <WhatshotIcon />,
      status: "PREPARING",
      disable:
        deliveryStatusToPoint[
          props.order.deliveryStatus as keyof typeof deliveryStatusToPoint
        ] >= deliveryStatusToPoint["PREPARING"],
    },
    {
      label: "Đang giao",
      icon: <DeliveryDiningIcon />,
      disable:
        deliveryStatusToPoint[
          props.order.deliveryStatus as keyof typeof deliveryStatusToPoint
        ] >= deliveryStatusToPoint["DELIVERING"],
        status: "DELIVERING",
      },
    {
      label: "Hoàn thành",
      icon: <CheckCircleIcon />,
      disable:
        deliveryStatusToPoint[
          props.order.deliveryStatus as keyof typeof deliveryStatusToPoint
        ] >= deliveryStatusToPoint["COMPLETED"],
    },
    {
      label: "Hoàn thành và thu tiền",
      icon: <RestaurantMenuIcon />,
      status: "COMPLETED",
      paymentStatus: "PAID",
      disable:
        deliveryStatusToPoint[
          props.order.deliveryStatus as keyof typeof deliveryStatusToPoint
        ] >= deliveryStatusToPoint["COMPLETED"],
    },
    {
      label: "Đã thu tiền",
      icon: <AttachMoneyIcon />,
      paymentStatus: "PAID",
      disable: props.order.paymentStatus === "PAID",
    },
  ];

  return (
    <>
      <Button
        variant="contained"
        endIcon={<EditIcon />}
        onClick={() => setOpen(true)}
      >
        Sửa
      </Button>
      {props.order.deliveryStatus === "PENDING" && (
        <>
          <LoadingButton
            variant="contained"
            color="warning"
            endIcon={<NotificationsIcon />}
            onClick={() => updateStatus({ deliveryStatus: "ACCEPTED" })}
            loading={loading}
            sx={{ animation: "shake 0.5s infinite" }}
          >
            Nhận
          </LoadingButton>{" "}
        </>
      )}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Cập nhật trạng thái"}
        </DialogTitle>
        <DialogActions>
          <List sx={{ pt: 0 }}>
            {actionConfigs.map((config, index) => (
              <ListItem key={index}>
                <ListItemButton
                  disabled={config.disable}
                  onClick={() =>
                    updateStatus({
                      deliveryStatus: config.status,
                      paymentStatus: config.paymentStatus,
                    })
                  }
                >
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: blue[100] }}>{config.icon}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={config.label} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openToast}
        onClose={() => setOpenToast(false)}
        color="success"
        autoHideDuration={1000}
      >
        <Alert
          onClose={() => setOpenToast(false)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Thành công
        </Alert>
      </Snackbar>
    </>
  );
};
