import type * as React from "react";
import { useContext, useState } from "react"; 
import { useQuery } from "@apollo/client";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { Order } from "./order";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { PAGINATE_ORDERS } from "./apis";

import { ShopContext } from "../shop-context";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, padding: "0 0.5rem" }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export function OrderTabs() {
  const { loading, shop } = useContext(ShopContext)
  const [value, setValue] = useState(0);

  if (loading) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            {...a11yProps(0)}
            icon={<HourglassBottomIcon />}
            sx={{ minWidth: "1rem", padding: "0.5rem 1.5rem" }}
          />
          <Tab
            {...a11yProps(1)}
            icon={<ThumbUpAltIcon />}
            sx={{ minWidth: "1rem", padding: "0.5rem 1.5rem" }}
          />
          <Tab
            {...a11yProps(2)}
            icon={<WhatshotIcon />}
            sx={{ minWidth: "1rem", padding: "0.5rem 1.5rem" }}
          />
          <Tab
            {...a11yProps(3)}
            icon={<DeliveryDiningIcon />}
            sx={{ minWidth: "1rem", padding: "0.5rem 1.5rem" }}
          />
          <Tab
            {...a11yProps(4)}
            icon={<CheckCircleIcon />}
            sx={{ minWidth: "1rem", padding: "0.5rem 1.5rem" }}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <OrderList shop={shop} filter={{ deliveryStatus: { eq: "PENDING" } }} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <OrderList
          shop={shop}
          filter={{ deliveryStatus: { eq: "ACCEPTED" } }}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <OrderList
          shop={shop}
          filter={{ deliveryStatus: { eq: "PREPARING" } }}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <OrderList
          shop={shop}
          filter={{ deliveryStatus: { eq: "DELIVERING" } }}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <OrderList
          shop={shop}
          filter={{ deliveryStatus: { eq: "COMPLETED" } }}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <OrderList shop={shop} filter={{}} />
      </CustomTabPanel>
    </>
  );
}

function OrderList(props: {
  shop: any;
  filter?: any;
  children?: React.ReactNode;
}) {
  const { loading, data, refetch } = useQuery(PAGINATE_ORDERS, {
    variables: {
      page: 1,
      limit: 10,
      filter: {
        shopId: { eq: props.shop.id },
        ...props.filter,
      },
      sort: { createdAt: "DESC" },
    },
  });
  if (loading) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }
  if (data.paginateOrders.docs.length === 0) {
    return (
      <Box sx={{ textAlign: "center", margin: "10vh" }}>
        <p>Không có đơn hàng</p>
      </Box>
    );
  }
  return (
    <div>
      {data.paginateOrders.docs.map((order: any) => (
        <Order order={order} key={order.id} refetch={refetch} />
      ))}
    </div>
  );
}
