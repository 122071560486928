import "./App.css";

import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ApolloProvider } from "@apollo/client";

import { client } from "./apollo";
import { SignIn } from "./pages/sign-in/page";
import { Shops } from "./pages/shops/page";
import { ShopDashboard } from "./pages/shop-dashboard/page";
import { Home, OnlyGuests, OnlySignedInUsers } from "./utils/auth-components";
import { OrderDetail } from "./pages/shop-dashboard/order/order-detail";
import { Menu } from "./pages/shop-dashboard/menu/page";
import { ProductDetails } from "./pages/shop-dashboard/menu/product";
import { ShopProvider } from "./pages/shop-dashboard/shop-context";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/shops",
    element: (
      <OnlySignedInUsers>
        <Shops />
      </OnlySignedInUsers>
    ),
  },
  {
    path: "/:shopCode",
    element: (
      <OnlySignedInUsers>
        <ShopProvider>
          <ShopDashboard />
        </ShopProvider>
      </OnlySignedInUsers>
    ),
  },
  {
    path: "/:shopCode/:orderSeqId",
    element: (
      <OnlySignedInUsers>
        <OrderDetail />
      </OnlySignedInUsers>
    ),
  },
  {
    path: "/:shopCode/menu",
    element: (
      <OnlySignedInUsers>
        <ShopProvider>
          <Menu />
        </ShopProvider>
      </OnlySignedInUsers>
    ),
  },
  {
    path: "/:shopCode/products/:productSeqId",
    element: (
      <OnlySignedInUsers>
        <ProductDetails />
      </OnlySignedInUsers>
    ),
  },
  {
    path: "sign-in",
    element: (
      <OnlyGuests>
        <SignIn />
      </OnlyGuests>
    ),
  },
]);

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const App: React.FC = () => (
  <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    <ApolloProvider client={client}>
      <RouterProvider router={router} />
    </ApolloProvider>
  </ThemeProvider>
);

export default App;
