import { gql, useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";

const defaultValue = { loading: true, shop: null };

export const ShopContext = React.createContext<any>(defaultValue);

const PAGINATE_SHOPS = gql`
  query PaginateShops($page: Int!, $limit: Int!, $filter: ShopFilter) {
    paginateShops(page: $page, limit: $limit, filter: $filter) {
      docs {
        id
        name
        code
        logo
      }
    }
  }
`;

export const ShopProvider = ({ children }: any) => {
  const { shopCode } = useParams();
  const { data, loading } = useQuery(PAGINATE_SHOPS, {
    variables: {
      limit: 1,
      page: 1,
      filter: { code: { eq: shopCode } },
    },
  });
  return (
    <ShopContext.Provider value={{ loading, shop: data?.paginateShops.docs[0] }}>
      {children}
    </ShopContext.Provider>
  );
};
