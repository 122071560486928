import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_QUET_API_ENDPOINT,
});

const authLink = setContext(({ operationName }) => {
  const accessToken = localStorage.getItem('accessToken');
  const role = localStorage.getItem('role');
  const roleMapping = localStorage.getItem('roleMapping');

  return {
    uri: `${process.env.REACT_APP_QUET_API_ENDPOINT}?operation=${operationName}`,
    headers: {
      'X-Quet-Api-Key': process.env.REACT_APP_QUET_API_KEY,
      'Accept-Language': 'vi-VN',
      // Add the access token if it exists
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      ...((accessToken && role) && { 'X-Quet-Use-Role': role }),
      ...((accessToken && roleMapping) && { 'X-Quet-Use-Role-Mapping': roleMapping }),
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
