import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import Dialog from "@mui/material/Dialog/Dialog";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogContentText from "@mui/material/DialogContentText/DialogContentText";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Button from "@mui/material/Button/Button";

const isAccessTokenValid = () => {
  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken) return false;
  try {
    const { exp } = jwtDecode(accessToken);
    const isExpired = Date.now() > exp! * 1000;
    if (isExpired) {
      throw new Error("Token expired");
    }
    return true;
  } catch (error) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("role");
    localStorage.removeItem("roleMapping");
    return false;
  }
};

export const OnlySignedInUsers = ({ children }: { children: React.ReactNode }) => {
  const accessTokenValid = isAccessTokenValid();
  if (accessTokenValid) return <>{children}</>;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [acknowledged, setAcknowledged] = useState(false);

  if (acknowledged) return <Navigate to="/sign-in" replace />;
  return (
    <Dialog
      open={!acknowledged}
      onClose={() => setAcknowledged(true)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Lỗi phiên đăng nhập"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Phiên đăng nhập đã hết hạn hoặc không hợp lệ. Vui lòng đăng nhập lại.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setAcknowledged(true)} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const Home = () => {
  const accessTokenValid = isAccessTokenValid();
  if (accessTokenValid) return <Navigate to="/shops" replace />;
  return <Navigate to="/sign-in" replace />;
};

export const OnlyGuests = ({ children }: { children: React.ReactNode }) => {
  const accessTokenValid = isAccessTokenValid();
  if (!accessTokenValid) return <>{children}</>;
  return <Navigate to="/shops" replace />;
};
