import Box from "@mui/material/Box";
import SimpleBottomNavigation from "./bottom-nav";
import { OrderTabs } from "./order/order-tabs";

export function ShopDashboard() {
  return (
    <Box
      sx={{
        width: "100%",
        padding: "0px",
      }}
    >
      <OrderTabs />
      <SimpleBottomNavigation />
    </Box>
  );
}
