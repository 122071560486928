import { gql } from "@apollo/client";

export const PAGINATE_ORDERS = gql`
  query PaginateOrders(
    $sort: OrderSort
    $filter: OrderFilter
    $page: Int!
    $limit: Int!
  ) {
    paginateOrders(sort: $sort, filter: $filter, page: $page, limit: $limit) {
      docs {
        id
        createdAt
        updatedAt
        seqId
        token
        paymentStatus
        deliveryStatus
        userId
        shopId
        tenantId
        cartId
        locale
        total {
          amount
          currency
        }
        subtotal {
          amount
          currency
        }
        discounted {
          amount
          currency
        }
        lineItems {
          id
          image
          name
          price {
            amount
            currency
          }
          quantity
          choseOptionValueIds
          options {
            values {
              id
              name
            }
          }
          note
        }
        appliedPromotions {
          code
          name
          description
          id
        }
        userEmail
        userPhone
        note
        payments {
          provider
          id
          referenceId
          data
        }
        shippingAddress
        deliveryType
        scheduledAt
        shop {
          name
          code
        }
      }
    }
  }
`;
