import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import SimpleBottomNavigation from "../bottom-nav";
import { ProductList } from "./products";
import { useContext } from "react";
import { ShopContext } from "../shop-context";

export const Menu = () => {
  const { loading } = useContext(ShopContext);

  if (loading) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Box>
      <ProductList />
      <SimpleBottomNavigation />
    </Box>
  );
};
